const DISTANCIA_FOCAL = 75

// Funcion para convertir angulo en grados, en un pan valido.
// TODO:No se usa
export const angleToNormalizedValue = (angle) => {
  // Normalize angle to be within 0 to 360 degrees
  angle = angle % 360
  if (angle < 0) {
    angle += 360
  }

  // Define the mapping using piecewise linear functions
  if (angle >= 0 && angle < 90) {
    // Interpolating between (0, 0.5) and (90, 0)
    return 0.5 - (angle / 180)
  } else if (angle >= 90 && angle < 180) {
    // Interpolating between (90, 0) and (180, -0.5)
    return 0 - ((angle - 90) / 180)
  } else if (angle >= 180 && angle < 270) {
    // Interpolating between (180, -0.5) and (270, 1)
    return -0.5 - ((angle - 180) / 90) * 0.5
  } else if (angle >= 270 && angle < 360) {
    // Interpolating between (270, 1) and (360, 0.5)
    return 1 - ((angle - 270) / 180)
  }
}

// Funcion que devuelve el angulo, a partir de 2 angulos y un numero entre 0 y 1
// -> Siendo 0 un valor mas cercano al primer angulo
// -> Siendo 1 un valor mas cercano al segundo
export const interpolateAngle = (angle1, angle2, t) => {
  // Asegura que los ángulos estén entre 0 y 360 grados
  angle1 = (angle1 % 360 + 360) % 360
  angle2 = (angle2 % 360 + 360) % 360

  // Calcula la diferencia entre los ángulos
  let delta = angle2 - angle1

  // Asegura que la diferencia esté en el rango [-180, 180]
  if (delta > 180) {
    delta -= 360
  } else if (delta < -180) {
    delta += 360
  }

  // Interpolación lineal entre los ángulos
  let interpolatedAngle = angle1 + delta * t

  // Asegura que el resultado esté entre 0 y 360 grados
  return (interpolatedAngle % 360 + 360) % 360
}

export const calcularCoordenadasPuntoEnLinea = (verticeA, verticeB, valor, ancho) => {
  const proporcion = valor / ancho // Normalizando el valor a un rango de 0 a 1
  const puntoX = verticeA[0] + proporcion * (verticeB[0] - verticeA[0])
  const puntoY = verticeA[1] + proporcion * (verticeB[1] - verticeA[1])
  return [puntoX, puntoY]
}

export const calcularFOV = (zoom) => {
  let fov = 1.78
  if (zoom === 0) {
    fov = 2 * Math.atan(5.76 / (2 * (4.25 + ((170 - 4.25) * zoom)))) * (180 / Math.PI)
  } else if (zoom < 0.015) {
    fov = 2 * Math.atan(5.76 / (2 * 1.2 * (4.25 + ((170 - 4.25) * zoom)))) * (180 / Math.PI)
  } else {
    fov = 2 * Math.atan(5.76 / (2 * 1.7 * (4.25 + ((170 - 4.25) * zoom)))) * (180 / Math.PI)
  }
  return fov
}

export const getConeAngle = (PAN, FOV, isLeft) => {
  return isLeft ? (PAN + 1) * 360 / 2 - DISTANCIA_FOCAL - FOV / 2 - (DISTANCIA_FOCAL / 4) : (PAN + 1) * 360 / 2 - DISTANCIA_FOCAL + FOV / 2 - (DISTANCIA_FOCAL / 4)
}

export const getYangle = (x) => {
  // return Math.acos(x) * (180 / Math.PI)
  return 90 - 90 * x
}
