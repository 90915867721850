<template>
  <v-card flat class="mt-4 layerItem">
    <v-card-title>
      <v-switch hide-details :label="item.title" v-model="visible" />
    </v-card-title>

    <v-card-text>
      <v-slider v-if="opacity > 0" v-model="opacity" label="Opacidad" min="10" max="100" step="10" hide-details />
      <v-checkbox v-if="item.legend" label="Mostrar leyenda" v-model="showLeyenda" />
      <v-row v-show="showLeyenda" no-gutters>
        <v-col
          v-for="(legend, index) in item.legend"
          :key="index"
        >
          <v-list>
            <v-list-item class="pa-0 ma-0">
              <v-list-item-avatar class="pa-0 ma-0">
                <v-avatar size="25" class="color-square" :style="{ backgroundColor: legend.color }" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ legend.nombre }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'

// import * as ArcGIS from '../../helpers/ArcGIS'

export default {
  props: {
    item: {
      type: Object,
      default: () => { return {} }
    }
  },

  data: () => ({
    visible: false,
    opacity: null,
    showLeyenda: false
  }),

  watch: {
    visible () {
      if (this.visible) {
        this.$emit('setVisible')
      }

      this.setVisibilityLayer({
        id: this.item.id,
        visible: this.visible,
        item: this.item
      })
      // ArcGIS.setVisibilityLayer(this.item.id, this.visible)
    },
    opacity () {
      this.setOpacityLayer({
        id: this.item.id,
        opacity: this.opacity / 100,
        item: this.item
      })
      // ArcGIS.setOpacityLayer(this.item.id, this.opacity / 100)
    }
  },

  methods: {
    ...mapActions('map', [
      'setVisibilityLayer',
      'setOpacityLayer'
    ])
  },

  created () {
    this.visible = this.item.visible

    if (this.item.opacity) {
      this.opacity = this.item.opacity * 100
    }
  }
}
</script>

<style scoped>
  .layerItem {
    background: #ebebeb;
    border-radius: 10px;
  }

  .color-square {
  border-radius: 0;
}
</style>
