var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-text-field',{staticClass:"mt-2",attrs:{"label":"Buscar","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn-toggle',{attrs:{"color":"primary","rounded":"","multiple":""},model:{value:(_vm.categoriasSelecionadas),callback:function ($$v) {_vm.categoriasSelecionadas=$$v},expression:"categoriasSelecionadas"}},_vm._l((_vm.categoriasMedios),function(tipo,i){return _c('v-btn',{key:i,attrs:{"small":""}},[_vm._v(_vm._s(tipo.text))])}),1),_c('v-data-table',{staticStyle:{"white-space":"normal"},attrs:{"headers":_vm.headers,"items":_vm.mediosFiltrados,"item-key":"ID_MEDIO","search":_vm.search,"height":"630px","item-class":_vm.getRowColor,"group-by":"ID_INCENDIO","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"disable-pagination":"","hide-default-footer":"","headers-length":6},on:{"click:row":_vm.zoomMedio,"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('p',[_vm._v("Datos no disponibles.")])]},proxy:true},{key:"item.MEDIO",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.MEDIO.substr(0,15) + (item.MEDIO.length > 15 ? '.' : '')))])]}},{key:"item.ULT_POSICION",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.ULT_POSICION ? item.ULT_POSICION.FECHA : '')))])]}},{key:"item.ESTADO",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.nombreEstado(item.ESTADO)))])]}},{key:"item.FECHA",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.FECHA ? item.FECHA : item.FECHA_BASE)))])]}},{key:"item.ID_INCENDIO_ANTERIOR",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$store.getters['incendio/getIncendioByIDAll'](item.ID_INCENDIO_ANTERIOR) ? _vm.$store.getters['incendio/getIncendioByIDAll'](item.ID_INCENDIO_ANTERIOR).MUNICIPIO : 'No ha actuado'))])]}},{key:"group.header",fn:function(ref){
var group = ref.group;
var toggle = ref.toggle;
var isOpen = ref.isOpen;
return [_c('td',{staticStyle:{"cursor":"pointer"},attrs:{"colspan":_vm.headers.length},on:{"click":toggle}},[_c('v-btn',{ref:group,style:(_vm.getGroupHeaderColor (group)),attrs:{"x-small":"","icon":""}},[(!isOpen)?_c('v-icon',[_vm._v("mdi-plus")]):_c('v-icon',[_vm._v("mdi-minus")])],1),_c('span',[_vm._v(" "+_vm._s(_vm.$store.getters['incendio/getIncendioByIDAll'](group) ? _vm.$store.getters['incendio/getIncendioByIDAll'](group).MUNICIPIO : 'Sin asignar'))])],1)]}},{key:"item.ACCIONES",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('v-layout',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","icon":"","small":"","disabled":_vm.disabledByRol() || item.ESTADO === 0},on:{"click":function($event){return _vm.editMedio(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar medio")])])],1)],1)]}}],null,true),model:{value:(_vm.mediosSelected),callback:function ($$v) {_vm.mediosSelected=$$v},expression:"mediosSelected"}}),_c('vx-dialog-edit-medio',{attrs:{"show":_vm.showDialogEditMedio,"idIncendio":_vm.idIncendio,"medioSector":_vm.medioEdit},on:{"aceptar":_vm.acceptEditMedioIncendio,"cancelar":_vm.cancelEditMedioIncendio,"deleteMedioIncendio":_vm.deleteMedioIncendio}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }