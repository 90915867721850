import request from '../request'

export default {
  getCamaras: function () {
    return request.get('getCamara', null)
  },
  getCamaraPTZ: function (id) {
    return request.get('getCamaraPTZ/' + id, null)
  },
  movePTZToPoint: function (id, pan) {
    return request.post('movePTZToPoint/' + id, { pan })
  }
}
