export const XPMobileSDK = window.XPMobileSDK
export const XPMobileSDKSettings = window.XPMobileSDKSettings

const URL_SERVER = 'http://172.16.24.230:8081'
const USER = 'srv-milestone\\milestone' // srv-milestone\milestone
const PASSWORD = 'LaRioja23' // LaRioja23
const LOGIN_TYPE = 'ActiveDirectory'

export const initialize = (result) => {
  console.log('--Initialize--')
  if (!XPMobileSDK.isLoaded()) {
    XPMobileSDK.onLoad = () => {
      try {
        XPMobileSDKSettings.MobileServerURL = URL_SERVER
        XPMobileSDK.connect(URL_SERVER)
      } catch (e) {
        console.log('Error al conectarse al servidor: \n', e)
      // setTimeout(XPMobileSDK.connect(URL_SERVER), 5000)
      }

      XPMobileSDK.addObserver({
        connectionDidConnect: connectionDidConnect,
        connectionDidLogIn: result,
        connectionFailedToLogIn: connectionFailedToLogIn
      })
    }
  } else {
    result()
  }
}

function connectionDidConnect () {
  console.log('Conexion establecida')

  XPMobileSDK.login(USER, PASSWORD, LOGIN_TYPE)
}

function connectionFailedToLogIn () {
  console.error('Error login')
}
